<template>
  <div class="accountlist">
    <van-nav-bar
      title="管理员工"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main" v-if="!nodata">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="onLoad"
      >
        <van-cell
          :border="false"
          center
          v-for="(item, index) in list"
          :key="index"
        >
          <template #icon>
            <van-image
              :src="item.avatar ? item.avatar : 'https://img.midbest.cn/uploads/image/order/kongjia/2020/10/05/b53233d98f7e4fe5965e8fc70a024b05.png'"
              round
              width="40"
              height="40"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </template>
          <template #title>
            <div class="van-cell__title_nickname van-ellipsis">{{item.nickname ? item.nickname : "未设置昵称"}}</div>
            <div class="van-cell__title_status">
              <van-tag plain :color="item.status == '1' ? '#07C160' : '#cccccc'">{{item.status == '1' ? "启用" : "停用"}}</van-tag>
              <span>{{item.edittime_str ? item.edittime_str : item.createtime_str}}</span>
            </div>
          </template>
          <template #right-icon>
            <van-icon name="edit" size="14" @click="Edit(index)" />
          </template>
        </van-cell>
      </van-list>
    </div>
    <div class="main" v-if="nodata">
      <van-empty description="暂无数据" />
    </div>
    <div class="add_btn">
      <van-button block type="danger" round @click="addacount">添加员工</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "AccountList",
  data() {
    return {
      shareid: 0,
      merchid: 0,
      CurrOffset: 0,
      CurrSize: 20,
      list: [],
      loading: false,
      finished: false,
      nodata: false,
      index: -1,
    }
  },
  mounted() {
    this.merchid = this.$route.query.merchid;
    this.shareid = this.$route.query.shareid;
    this.$parent.getmerchname(this.merchid, "管理子账号");
    this.init();
  },
  activated() {
    this.updateList();
  },
  methods: {
    init() {
      if (this.index == -1) {
        this.onLoad();
      }
    },
    onClickLeft() {
      this.$router.back();
    },
    addacount() {
      this.index = -1;
      this.$router.push({
        name: "addAccount",
        query: {
          id: 0,
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },
    onLoad() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/shops/get_list",
          _that.$qs.stringify({
            offset: _that.CurrOffset,
            size: _that.CurrSize
          })
        )
        .then(res => {
          console.log(res);
          _that.loading = false;
          if (res.data.code == 100000) {
            if (_that.CurrOffset == 0) {
              _that.list = res.data.data;
            }else {
              res.data.data.forEach(item => {
                _that.list.push(item);
              })
            }
            if (res.data.data.length < _that.CurrSize) {
              _that.finished = true;
            }else {
              _that.finished = false;
            }
            _that.CurrOffset += res.data.data.length;
          }else {
            _that.finished = true;
            if (_that.CurrOffset == 0) {
              _that.nodata = true;
            }else {
              _that.nodata = false;
            }
          }
        })
        .catch(err => {
          console.log(err);
          _that.finished = true;
          if (_that.CurrOffset == 0) {
            _that.nodata = true;
          }else {
            _that.nodata = false;
          }
        })
    },
    Edit(index) {
      this.index = index;
      this.$router.push({
        name: "addAccount",
        query: {
          id: this.list[index].id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },
    updateList() {
      let _that = null;
      _that = this;
      if (_that.index > -1) {
        _that.loading = true;
        let id = 0;
        id = _that.list[_that.index].id;
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
        _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/shops/get_one",
            _that.$qs.stringify({
              id: id
            })
          )
          .then(res => {
            console.log(res);
            _that.loading = false;
            if (res.data.code == 100000) {
              _that.list[_that.index] = res.data.data;
            }else {
              _that.$toast(res.data.msg ? res.data.msg : "");
            }
            _that.index = -1;
          })
          .catch(err => {
            console.log(err);
            _that.loading = false;
            _that.index = -1;
          })
      }
    }
  },
}
</script>
<style lang="less">
.accountlist {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  position: relative;
  z-index: 2;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    padding-bottom: 60px;
    .van-cell {
      .van-image {
        margin-right: 10px;
      }
      .van-cell__title {
        flex: 1;
        text-align: left;
        margin-right: 10px;
        overflow: hidden;
        .van-cell__title_status {
          .van-tag {
            margin-right: 6px;
          }
        }
      }
      .van-icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: block;
      }
    }
  }
  .add_btn {
    position: fixed;
    padding: 5px 10px 10px;
    background-color: #fff;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>